import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";
import { Helmet } from "react-helmet";
import visionDistribution from "../../src/assets/token-distribution.svg";
import TableChartMobile from "../../src/assets/table-chart-mobile.svg";

function VisionTokens() {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    async function fetchPrice() {
      const response = await axios.get(`${API_URL}/pricing`);
      setPrice(response.data.visionPrice);
    }
    fetchPrice();
  }, [price]);

  return (
    <div className="vision-container">
      <Helmet>
        <title>About the VISION token - APY.vision</title>
        <meta
          name="description"
          content="Our mission is to help all liquidity providers across the DeFi space make confident investment decisions and successfully maximise their returns."
        />
        <meta
          property="og:title"
          content="About the VISION token - APY.vision"
        />
        <meta
          property="og:description"
          content="Our mission is to help all liquidity providers across the DeFi space make confident investment decisions and successfully maximise their returns."
        />
        <meta property="og:url" content="https://apy.vision" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@APYVision" />
        <meta
          name="twitter:title"
          content="About the VISION token - APY.vision"
        />
        <meta
          name="twitter:description"
          content="Our mission is to help all liquidity providers across the DeFi space make confident investment decisions and successfully maximise their returns."
        />
        <meta name="twitter:creator" content="@APYVision" />
        <meta
          property="twitter:image"
          content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
        />
        <meta name="twitter:domain" content="apy.vision" />
      </Helmet>
      <div className="vision-header">
        <h1 className="vision-container__heading">
          The VISION membership token
        </h1>

        <p className="vision-container__text">
          Our mission is to help all liquidity providers across the DeFi space
          make confident investment decisions and successfully maximise their
          returns. To do so, we offer a wide range of membership options,
          including the VISION membership token and various subscription plans.
        </p>
        <div className="token">
          <div className="token-price">
            <h3 className="token-price__heading">Get VISION tokens:</h3>
            <p className="token-price__value">1 VISION = ${price.toFixed(2)}</p>
          </div>
          <div className="token-button">
            <a
              href="https://v2.info.uniswap.org/pair/0xa323fc62c71b210e54171887445d7fca569d8430"
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                fontWeight: "700",
                fontsize: "16px",
                lineHeight: "24px",
                margin: "0px",
              }}
            >
              <button className="button button-width uniswap-button">
                via Uniswap
              </button>
            </a>
            <a
              href="https://polygon.balancer.fi/#/trade/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x034b2090b579228482520c589dbD397c53Fc51cC "
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                fontWeight: "700",
                fontsize: "16px",
                lineHeight: "24px",
                margin: "0px",
              }}
            >
              <button className="button button-width">via Balancer</button>
            </a>
          </div>
        </div>
        <div className="distribution">
          <div className="distribution-info">
            <div className="distribution-info__content">
              <h3 className="distribution-info__content-heading">
                The token that gives you 20/20 vision
              </h3>
              <p className="distribution-info__content-text">
                By simply holding enough tokens in a wallet, members can
                automatically unlock pro features, to a ratio of 100 VISION
                tokens per $10,000 tracked. This ensures that members who are
                not big portfolio holders can benefit by holding just a small
                amount of VISION tokens in their wallet. As more liquidity is
                provided, more VISION tokens can be added, it’s that simple!
              </p>
              <p className="distribution-info__content-note">
                Note: the VISION token is not a security token. It does not have
                any inherent value and is not meant to be speculated on. We are
                not an ICO or claim to return you any gains by acquiring the
                VISION token. This is simply a membership token and not an
                asset.
              </p>
              <Link
                to="/pro-plans"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "700",
                  fontsize: "16px",
                  lineHeight: "24px",
                  margin: "0px",
                  cursor: "pointer",
                }}
              >
                <button className="button button-width">View our plans</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionTokens;
