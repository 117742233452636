import React, { useState, useLayoutEffect } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};

export const IsMobileDevice = () => {
  const windowSize = useWindowSize();
  const width = windowSize[0];
  if (width < 600) return true;
  return false;
};

export const IsMobileDeviceMedium = () => {
  const windowSize = useWindowSize();
  const width = windowSize[0];
  if (width < 768) return true;
  return false;
};

export const IsTabletDevice = () => {
  const windowSize = useWindowSize();
  const width = windowSize[0];
  if (width < 877) return true;
  return false;
};

export const IsMediumTabletDevice = () => {
  const windowSize = useWindowSize();
  const width = windowSize[0];
  if (width < 1001) return true;
  return false;
};

export const IsDesktopDevice = () => {
  const windowSize = useWindowSize();
  const width = windowSize[0];
  if (width < 1280) return true;
  return false;
};

// export const handleBodyScroll = (mode) => {
//   if (mode) document.body.classList.add('modal-open');
//   else document.body.classList.remove('modal-open');
// };
