import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ExternalLink } from "react-feather";
import { ArrowRight } from "react-feather";
import {
  MenuEnterpriseElem,
  MenuEnterpriseListElem,
  MenuEnterpriseCloseElem,
} from "./Menu.styles";
import logo from "../../assets/logo.svg";
import { IsMobileDevice } from "../../helpers/window";
import { useWindowSize } from "@react-hook/window-size";
import IconDown from "../../assets/icon.svg";
export default ({ isOpen, onClose }) => {
  const isMobileDevice = IsMobileDevice();
  const [width, height] = useWindowSize();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <MenuEnterpriseElem
      className="main-menu"
      aria-label="Main menu"
      isOpen={isOpen}
    >
      <div isOpen={isOpen} className="mobile-logo">
        <img src={logo} alt="logo" />
      </div>
      <MenuEnterpriseCloseElem
        href=""
        className="menu-close"
        aria-label="Close main menu"
        onClick={onClose}
        isOpen={isOpen}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99992 6.58579L14.3639 0.221826L15.7781 1.63604L9.41414 8L15.7781 14.364L14.3639 15.7782L7.99992 9.41421L1.63596 15.7782L0.221749 14.364L6.58571 8L0.221749 1.63604L1.63596 0.221826L7.99992 6.58579Z"
            fill="#6B57B8"
          />
        </svg>
      </MenuEnterpriseCloseElem>
      {isMobileDevice || width <= 865 ? (
        <MenuEnterpriseListElem isOpen={isOpen}>
          <ul className="header-container__nav">
            <li
              className="header-container__nav-link"
              onClick={() => {
                window.open("http://docs.apy.vision/", "_blank");
                onClose();
              }}
            >
              <Link>
                <ArrowRight
                  color="red"
                  size="14"
                  className="header-container__nav-link-icon"
                />
                Documentation
              </Link>
            </li>
            <li>
              <div class="dropdown">
                <button class="dropbtn">Use cases</button>
                <img src={IconDown} />
                <div class="dropdown-content">
                  <a
                    href="https://docs.apy.vision/docs/defi-protocols "
                    target="_blank"
                  >
                    DeFi Protocols
                  </a>
                  <a
                    href="https://docs.apy.vision/docs/portfolio-trackers "
                    target="_blank"
                  >
                    Portfolio trackers
                  </a>
                  <a
                    href="https://docs.apy.vision/docs/tax-accounting "
                    target="_blank"
                  >
                    Tax & accounting
                  </a>
                  <a
                    href="https://docs.apy.vision/docs/wallet-providers "
                    target="_blank"
                  >
                    Wallet providers
                  </a>
                </div>
              </div>
            </li>
            <li
              className="header-container__nav-link"
              onClick={() => {
                window.open(" https://blog.apy.vision/", "_blank");
                onClose();
              }}
            >
              <Link>
                <ArrowRight
                  color="red"
                  size="14"
                  className="header-container__nav-link-icon"
                />
                Blog
              </Link>
            </li>
            <li className="header-container__nav-link" onClick={onClose}>
              <Link to="/partners">
                <ArrowRight
                  color="red"
                  size="14"
                  className="header-container__nav-link-icon"
                />
                Partners
              </Link>
            </li>
          </ul>
        </MenuEnterpriseListElem>
      ) : (
        <MenuEnterpriseListElem isOpen={isOpen}>
          <ul className="header-container__nav">
            <li
              className="header-container__nav-link"
              onClick={() => {
                window.open("http://docs.apy.vision/", "_blank");
                onClose();
              }}
            >
              <Link>
                <ArrowRight
                  color="red"
                  size="14"
                  className="header-container__nav-link-icon"
                />
                Documentation
              </Link>
            </li>

            <li>
              <div class="dropdown">
                <button class="dropbtn">Use cases</button>
                <img src={IconDown} />
                <div class="dropdown-content">
                  <a
                    href="https://docs.apy.vision/docs/defi-protocols "
                    target="_blank"
                  >
                    DeFi Protocols
                  </a>
                  <a
                    href="https://docs.apy.vision/docs/portfolio-trackers "
                    target="_blank"
                  >
                    Portfolio trackers
                  </a>
                  <a
                    href="https://docs.apy.vision/docs/tax-accounting "
                    target="_blank"
                  >
                    Tax & accounting
                  </a>
                  <a
                    href="https://docs.apy.vision/docs/wallet-providers "
                    target="_blank"
                  >
                    Wallet providers
                  </a>
                </div>
              </div>
            </li>
            <li
              className="header-container__nav-link"
              onClick={() => {
                window.open(" https://blog.apy.vision/", "_blank");
              }}
            >
              <Link>
                <ArrowRight
                  color="red"
                  size="14"
                  className="header-container__nav-link-icon"
                />
                Blog
              </Link>
            </li>
            <li className="header-container__nav-link">
              <Link to="/partners">
                <ArrowRight
                  color="red"
                  size="14"
                  className="header-container__nav-link-icon"
                />
                Partners
              </Link>
            </li>
          </ul>
        </MenuEnterpriseListElem>
      )}
    </MenuEnterpriseElem>
  );
};
