import React from "react";
import Home from "./pages/Home";
import Header from "./components/Header";
import ProPlans from "./pages/ProPlans";
import Partners from "./pages/Partners";
import VisionTokens from "./pages/VisionTokens";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Membership from "./pages/Membership";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition";
import styled from "styled-components";
import { HelpCircle } from "react-feather";
import Apis from "./pages/Apis";

const FreshDeskWidget = styled.div`
  position: fixed;
  bottom: 20px;
  background-color: rgb(6, 181, 221);
  right: 30px;
  border-radius: 30px 8px 30px 30px;
  color: rgb(255, 255, 255);
  z-index: 2147483647;
  cursor: pointer;
  padding: 8px;
`;

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-conditions" component={TermsCondition} />
          <Route path="/partners" component={Partners} />
          <Route path="/apis" component={Apis} />
        </Switch>
        <Footer />
      </div>
      <FreshDeskWidget onClick={() => window.open("https://apyvision.freshdesk.com/support/tickets/new", "_blank")}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <HelpCircle size={18} />
          <p
            style={{
              marginBottom: 0,
              fontWeight: 400,
              marginLeft: 6,
              marginRight: 8,
            }}
          >
            Help
          </p>
        </div>
      </FreshDeskWidget>
    </Router>
  );
}

export default App;
