import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/white_logo.svg";
import Mailchimp from "../Mailchimp";

function Footer() {
  const location = useLocation();
  return (
    <div className="footer-section">
      <div className="footer-container">
        <footer className="footer">
          <div className="logo-container">
            <div className="logo">
              <img src={logo} className="App-logo" alt="logo" />
            </div>

            <p className="text">All-in-one liquidity pool analytics and yield farming rewards tracking tool</p>
          </div>
          <div className="footer-links">
            <ul className="footer-navigation">
              <li className="footer-navigation__link">
                <a href="https://app.apy.vision/" target="_blank" rel="noopener noreferrer">
                  Access our app
                </a>
              </li>
              <li className="footer-navigation__link">
                <a href="https://apyvision.freshdesk.com/support/home" target="_blank" rel="noopener noreferrer">
                  Support center
                </a>
              </li>
              <li className="footer-navigation__link">
                <a href="https://apyvision.hellonext.co/" target="_blank" rel="noopener noreferrer">
                  Feature requests
                </a>
              </li>
            </ul>
            <ul className="footer-navigation">
              <li className="footer-navigation__link">
                <Link to="/apis">APIs</Link>
              </li>
              <li className="footer-navigation__link">
                <a href="https://discord.com/invite/ePfGAYbqUq" target="_blank" rel="noopener noreferrer">
                  Discord
                </a>
              </li>
              <li className="footer-navigation__link">
                <a href="https://twitter.com/apyvision" target="_blank" rel="noopener noreferrer">
                  Twitter
                </a>
              </li>
            </ul>

            <ul className="footer-navigation">
              <li className="footer-navigation__link">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="footer-navigation__link">
                <Link to="/terms-conditions">Terms and Conditions</Link>
              </li>
            </ul>
          </div>
          <Mailchimp />
        </footer>
      </div>
    </div>
  );
}

export default Footer;
