import React from "react";
import enterprise from "./enterprise.png";

const EnterpriseLogo = ({ width }) => {
  return (
    <img
      style={
        width <= 1024
          ? {
              display: "block",
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
            }
          : {}
      }
      src={enterprise}
      alt={"enterprise"}
    />
  );
};

export default EnterpriseLogo;
