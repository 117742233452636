import { Link } from "react-router-dom";
import Partner from "../../assets/partners.svg";

export default () => {
  return (
    <>
      <div className="partnerinfo-container">
        <img
          src={Partner}
          alt="Partners blank image"
          className="partnerinfo-container__image"
        />
        <div className="partnerinfo-container__info">
          <h3 className="partnerinfo-container__info-heading">
            Partnering for the future of DeFi
          </h3>
          <p className="partnerinfo-container__info-text">
            We are proud to have partnerships with some of the largest players,
            to grow the development of Decentralised Finance and Liquidity
            Providing
          </p>
          <Link to="/partners">
            <button className="partnerinfo-container__info-button">
              <span className="button-text">View our partnerships</span>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};
