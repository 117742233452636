import React from "react";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div className="vision-container">
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Our mission is to help all liquidity providers across the DeFi space make confident investment decisions and successfully maximise their returns."
        />
        <meta
          property="og:title"
          content="About the VISION token - APY.vision"
        />
        <meta
          property="og:description"
          content="Our mission is to help all liquidity providers across the DeFi space make confident investment decisions and successfully maximise their returns."
        />
        <meta property="og:url" content="https://apy.vision" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@APYVision" />
        <meta
          name="twitter:title"
          content="About the VISION token - APY.vision"
        />
        <meta
          name="twitter:description"
          content="Our mission is to help all liquidity providers across the DeFi space make confident investment decisions and successfully maximise their returns."
        />
        <meta name="twitter:creator" content="@APYVision" />
        <meta
          property="twitter:image"
          content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
        />
        <meta name="twitter:domain" content="apy.vision" />
      </Helmet>
      <div className="vision-header">
        <h1 className="vision-container__heading">Privacy Policy</h1>

        <p className="vision-container__text">
          <div className="policy-text">
            At Apy.vision, accessible from{" "}
            <a href="https://apy.vision/" target="_blank">
              https://apy.vision/
            </a>{" "}
            and&nbsp;
            <a href="https://app.apy.vision/" target="_blank">
              https://app.apy.vision/
            </a>
            , one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains types of information that is
            collected and recorded by Apy.vision and how we use it.
          </div>

          <div className="policy-text">
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </div>

          <div className="policy-text">
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Apy.vision. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </div>
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">Consent</h3>
        <p className="distribution-info__content-text">
          By using our website and application, you hereby consent to our
          Privacy Policy and agree to its terms.
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">
          How we use your information
        </h3>

        <p className="distribution-info__content-text">
          We use the information we collect in various ways, including to:
        </p>

        <ul className="distribution-info_ul">
          <li>Provide, operate, and maintain our website</li>
          <li>Improve and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes (via our newsletter)
          </li>
        </ul>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">
          Cookies and Web Beacons
        </h3>

        <p className="distribution-info__content-text">
          Like any other website, apy.vision uses 'cookies'. The information is
          used to optimize the users' experience.
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">
          Advertising Partners Privacy Policies
        </h3>

        <p className="distribution-info__content-text">
          Apy.vision is not working with any advertising partners.
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">
          Third Party Privacy Policies
        </h3>

        <p className="distribution-info__content-text">
          Apy.vision's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options. You can choose to disable cookies
          through your individual browser options. To know more detailed
          information about cookie management with specific web browsers, it can
          be found at the browsers' respective websites.
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">
          CCPA Privacy Rights (Do Not Sell My Personal Information)
        </h3>

        <p className="distribution-info__content-text">
          Under the CCPA, among other rights, California consumers have the
          right to: Request that a business that collects a consumer's personal
          data disclose the categories and specific pieces of personal data that
          a business has collected about consumers. Request that a business
          delete any personal data about the consumer that a business has
          collected. Request that a business that sells a consumer's personal
          data, not sell the consumer's personal data. If you make a request, we
          have one month to respond to you. If you would like to exercise any of
          these rights, please contact us.
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">
          GDPR Data Protection Rights
        </h3>

        <p className="distribution-info__content-text">
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following: The right
          to access – You have the right to request copies of your personal
          data. We may charge you a small fee for this service. The right to
          rectification – You have the right to request that we correct any
          information you believe is inaccurate. You also have the right to
          request that we complete the information you believe is incomplete.
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions. The right to restrict
          processing – You have the right to request that we restrict the
          processing of your personal data, under certain conditions. The right
          to object to processing – You have the right to object to our
          processing of your personal data, under certain conditions. The right
          to data portability – You have the right to request that we transfer
          the data that we have collected to another organization, or directly
          to you, under certain conditions. If you make a request, we have one
          month to respond to you.
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">Contact Us</h3>

        <p className="distribution-info__content-text">
          For any questions or concerns regarding your privacy, you may contact
          us using the following details:
        </p>

        <p className="distribution-info__content-text">
          APY Vision
          <br />
          info@apy.vision
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
