import Subtract from "../../assets/subtract.svg";
import Key from "../../assets/key.svg";
import Rocket from "../../assets/rocket.svg";
import Integrations from "../../assets/partners.png";
import IntegrationsMobile from "../../assets/mobile-partners.svg";
import CircleRight from "../../assets/circle-chevron-right.svg";
import { IsMobileDevice } from "../../helpers/window";
import Carousel from "react-multi-carousel";
import Insider from "../../assets/insider.svg";
import CoinGecko from "../../assets/coingecko.svg";
import CoinDesk from "../../assets/coindesk.svg";
import CoinTelegraph from "../../assets/cointelegraph.svg";
import Yahoo from "../../assets/yahoo.svg";
import DefiPulse from "../../assets/defipulse.svg";
import DefiPrime from "../../assets/defiprime.svg";
import Investing from "../../assets/investing.svg";
import Nansen from "../../assets/nansen.svg";

import "react-multi-carousel/lib/styles.css";
import { useWindowSize } from "@react-hook/window-size";

export default function Info() {
  const isMobileDevice = IsMobileDevice();
  const [width, height] = useWindowSize();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 865, min: 0 },
      items: 1,
    },
  };

  const featured = [
    {
      icon: Insider,
      link: "https://www.businessinsider.com/top-passive-ways-earn-crypto-ceo-of-blockchain-investment-firm-2022-5",
    },
    {
      icon: CoinGecko,
      link: "https://assets.coingecko.com/reports/Research/Deep-Dive-into-Decentralized-Leveraged-ETFs-Part-2.pdf",
    },
    {
      icon: CoinDesk,
      link: "https://www.coindesk.com/markets/2021/01/05/ethereum-the-last-bastion-for-yield",
    },
    {
      icon: CoinTelegraph,
      link: "https://cointelegraph.com/press-releases/impermanent-loss-cryptos-silent-killer-threatens-the-core-tenets-of-defi-bancor",
    },
    {
      icon: Yahoo,
      link: "https://finance.yahoo.com/news/impermanent-loss-crypto-silent-killer-181500277.html",
    },
    { icon: DefiPulse, link: "https://www.defipulse.com/defi-list" },
    { icon: DefiPrime, link: "https://defiprime.com/defi-analytics" },
    {
      icon: Investing,
      link: "https://www.investing.com/news/cryptocurrency-news/quickswap--the-dex-curating-an-economical-and-scalable-way-to-swap-tokens-2507621",
    },
    {
      icon: Nansen,
      link: "https://www.nansen.ai/post/announcing-defi-paradise-the-defi-overview-dashboard",
    },
  ];

  return (
    <div className="section info">
      <div className="info-container">
        <div className="info-wrapper">
          {isMobileDevice || width <= 865 ? (
            <Carousel
              containerClass="carousel-container"
              responsive={responsive}
              showDots={true}
              removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            >
              <div className="info">
                <div className="info-image">
                  <img src={Subtract} alt="icon" />
                </div>
                <h3 className="info-title">
                  Track your liquidity providing performance and impermanent
                  losses across chains and AMMs
                </h3>
                <p className="info-text">
                  Manage your liquidity pools and track impermanent losses for
                  more accurate profit calculation.
                </p>
              </div>
              <div className="info">
                <div className="info-image">
                  <img src={Key} alt="icon" />
                </div>
                <h3 className="info-title">
                  Monitor your vault and farming activities, and calculate your
                  net profits
                </h3>
                <p className="info-text">
                  Track your yield farming activities and automatically
                  calculate your farming rewards.
                </p>
              </div>
              <div className="info">
                <div className="info-image">
                  <img src={Rocket} alt="icon" />
                </div>
                <h3 className="info-title">
                  Analyse pools historic APYs and find the best liquidity pools
                  before everyone
                </h3>
                <p className="info-text">
                  Discover the best liquidity pools before anyone else. Compare
                  pool performance by APY, impermanent loss, and collected fees.
                </p>
              </div>
            </Carousel>
          ) : (
            <>
              <div className="info">
                <div className="info-image">
                  <img src={Subtract} alt="icon" />
                </div>
                <h3 className="info-title">
                  Track your liquidity providing performance and impermanent
                  losses across chains and AMMs
                </h3>
                <p className="info-text">
                  Manage your liquidity pools and track impermanent losses for
                  more accurate profit calculation.
                </p>
              </div>
              <div className="info">
                <div className="info-image">
                  <img src={Key} alt="icon" />
                </div>
                <h3 className="info-title">
                  Monitor your vault and farming activities, and calculate your
                  net profits
                </h3>
                <p className="info-text">
                  Track your yield farming activities and automatically
                  calculate your farming rewards.
                </p>
              </div>
              <div className="info">
                <div className="info-image">
                  <img src={Rocket} alt="icon" />
                </div>
                <h3 className="info-title">
                  Analyse pools historic APYs and find the best liquidity pools
                  before everyone
                </h3>
                <p className="info-text">
                  Discover the best liquidity pools before anyone else. Compare
                  pool performance by APY, impermanent loss, and collected fees.
                </p>
              </div>
            </>
          )}
        </div>
        <div className="integrations">
          <div className="integrations__heading">
            <h3>Integrations</h3>
          </div>
          <div className="integrations__images">
            {width <= 768 ? (
              <img src={IntegrationsMobile} alt="Integrtions" />
            ) : (
              <img src={Integrations} alt="Integrtions" />
            )}
          </div>
          <div className="integrations__button">
            <a
              href="https://app.apy.vision/supported-amms-chains"
              target="_blank"
            >
              See all supported chains and protocols{" "}
              <img src={CircleRight} alt="Integrtions" />
            </a>
          </div>
        </div>
      </div>

      <div className="featured info">
        <div className="featured__heading">
          <h3>Featured on</h3>

          <div className={"featured__container"}>
            {width > 1030 ? (
              <>
                <div className={"featured__container-flex"}>
                  {featured.slice(0, 4).map((feature, index) => (
                    <a
                      href={feature.link}
                      target={"_blank"}
                      className={"children"}
                      key={index}
                    >
                      <img src={feature.icon} />
                    </a>
                  ))}
                </div>

                <div className={"featured__container-flex"}>
                  {featured.slice(4).map((feature, index) => (
                    <a
                      href={feature.link}
                      target={"_blank"}
                      className={"children"}
                      key={index}
                    >
                      <img src={feature.icon} />
                    </a>
                  ))}
                </div>
              </>
            ) : (
              <div className={"featured__container-flex"}>
                {featured.map((feature, index) => (
                  <a
                    href={feature.link}
                    target={"_blank"}
                    className={"children"}
                    key={index}
                  >
                    <img src={feature.icon} />
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
