import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { PARTNERS } from "../constants";

const partnersData = [
  {
    name: PARTNERS.TheGraph,
    logo: "graph.svg",
    text: `Indexing protocol for querying networks like Ethereum and IPFS.`,
    link: "https://thegraph.com/",
  },
  {
    name: PARTNERS.Nansen,
    logo: "nansen.png",
    text: `Blockchain analytics platform combining on-chain data with millions of
    wallet labels.`,
    link: "https://nansen.ai/",
  },
  {
    name: PARTNERS.Balancer,
    logo: "Balancer.svg",
    text: "Automated portfolio manager and trading platform",
    link: "https://balancer.fi/",
  },
  {
    name: PARTNERS.Uniswap,
    logo: "Uniswap.svg",
    text: "Largest decentralized token swapping exchange on Ethereum.",
    link: "https://uniswap.org/",
  },
  {
    name: PARTNERS["1inch"],
    logo: "1inch.svg",
    text: "1inch Network is a decentralized exchange (DEX) aggregator to help users discover the best trade prices for tokens.",
    link: "https://app.1inch.io/",
  },
  {
    name: PARTNERS.Aura,
    logo: "aura.svg",
    text: "Aura is boosting DeFi stakeholders' yield potential and governance power, starting with Balancer.",
    link: "https://aura.finance/ ",
  },
  {
    name: PARTNERS.Avalanche,
    logo: "avax.svg",
    text: "Avalanche is a high throughput smart contract blockchain platform.",
    link: "https://www.avax.network/",
  },
  {
    name: PARTNERS.TraderJoe,
    logo: "traderjoe.png",
    text: "Trader Joe is a one-stop decentralized trading platform on the Avalanche network.",
    link: "https://traderjoexyz.com/",
  },
  {
    name: PARTNERS.SpiritSwap,
    logo: "spiritswap.png",
    text: "SpiritSwap is a decentralized exchange using the constant product automated market maker model on the Fantom network.",
    link: "https://www.spiritswap.finance/",
  },
  {
    name: PARTNERS.Sushiswap,
    logo: "Sushiswap.svg",
    text: " Innovative decentralized exchange with multi-chain approach.",
    link: "https://sushi.com/",
  },
  {
    name: PARTNERS.Quickswap,
    logo: "Quickswap.svg",
    text: "Decentralized exchange on MATIC network for low fees.",
    link: "https://quickswap.exchange/",
  },
  {
    name: PARTNERS.Polygon,
    logo: "Polygon.svg",
    text: "Layer 2 solution with low fees and convenient bridges.",
    link: "https://matic.network/",
  },
  {
    name: PARTNERS.Index,
    logo: "Index.svg",
    text: "DAO with purpose of building decentralized financial index products.",
    link: "https://www.indexcoop.com/",
  },
  {
    name: PARTNERS.FraxFinance,
    logo: "Frax-Finance.svg",
    text: "Innovative algorithmic stablecoin that uses fractional reserves.",
    link: "https://frax.finance/",
  },

  {
    name: PARTNERS.FantomFoundation,
    logo: "Fantom.svg",
    text: "Fantom is a fast, high-throughput open-source smart contract platform for digital assets and dApps.",
    link: "https://fantom.foundation/",
  },
  {
    name: PARTNERS.VisorFinance,
    logo: "Visor.svg",
    text: "Visor uses NFT Smart Vaults for liquidity provisioning and active liquidity management on Uniswap v3.",
    link: "https://www.visor.finance/",
  },

  {
    name: PARTNERS.PickleFinance,
    logo: "Pickle-Finance.svg",
    text: "Pool yield farming with Pickle to make farming more efficient.",
    link: "https://pickle.finance/",
  },
  {
    name: PARTNERS.GnosisChain,
    logo: "xdai.svg",
    text: "The Gnosis Chain is a stable payments EVM blockchain designed for fast and inexpensive transactions.",
    link: "https://www.xdaichain.com/",
  },
  {
    name: PARTNERS.Coingecko,
    logo: "coingecko.svg",
    text: "CoinGecko provides a 360-degree overview and fundamental analysis of the crypto market.",
    link: "https://www.coingecko.com/",
  },
  {
    name: PARTNERS.Aavegotchi,
    logo: "aavegottchii.svg",
    text: "Aavegotchi - Digital frens for the crypto generation! Aavegotchis are playable NFTs with a DeFi core. ",
    link: "https://aavegotchi.com/",
  },
  {
    name: PARTNERS.Swapr,
    logo: "swapr.svg",
    text: "A DXdao project that is a governance-enabled automated market maker with adjustable fees.",
    link: "https://swapr.eth.link",
  },
  {
    name: PARTNERS.Warp,
    logo: "Warp.svg",
    text: "Leverage your LP tokens and borrow stablecoins.",
    link: "https://www.warp.finance/",
  },

  {
    name: PARTNERS.Harvest,
    logo: "Harvest.svg",
    text: "International cooperative of humble farmers pooling resources together in order to earn DeFi yields.",
    link: "https://harvest.finance/",
  },

  {
    name: PARTNERS.BenchmarkProtocol,
    logo: "Benchmark-Protocol.svg",
    text: "Supply elastic collateral and hedging token, driven by the Volatility Index.",
    link: "https://benchmarkprotocol.finance/",
  },
  {
    name: PARTNERS["88mph"],
    logo: "88mph.svg",
    text: "Lend your crypto assets at a fixed interest rate or purchase floating-rate bonds.",
    link: "https://88mph.app/",
  },

  {
    name: PARTNERS.ValueDefi,
    logo: "Value-DeFi.svg",
    text: "Platform and suite of products that aim to bring fairness, true value, and innovation to Decentralized Finance.",
    link: "https://valuedefi.io/",
  },
  {
    name: PARTNERS.Pangolin,
    logo: "pangolin.png",
    text: "A community-driven decentralized exchange for Avalanche and Ethereum assets with fast settlement, low transaction fees.",
    link: "https://pangolin.exchange/",
  },
  {
    name: PARTNERS.YAMFinance,
    logo: "YAM-Finance.svg",
    text: "Fair finance by the people, for the people. Fair launch, open participation, and inclusive community.",
    link: "https://yam.finance/",
  },
];
export default function Partners() {
  const shouldManipulateImage = (partner) =>
    [
      PARTNERS.Nansen,
      PARTNERS.Avalanche,
      PARTNERS.Aura,
      PARTNERS["1inch"],
      PARTNERS.SpiritSwap,
      PARTNERS.Pangolin,
      PARTNERS.TraderJoe,
      PARTNERS.TheGraph,
    ].some((p) => p === partner);

  return (
    <Fragment>
      <div className="partners-container">
        <Helmet>
          <title>
            Growing the liquidity providing space with our partners - APY.vision
          </title>
          <meta
            name="description"
            content="We are proud to have partnerships with some of the largest players in the space, including Uniswap, Sushiswap, Quickswap and more"
          />
          <meta
            property="og:title"
            content="Growing the liquidity providing space with our partners - APY.vision"
          />
          <meta
            property="og:description"
            content="We are proud to have partnerships with some of the largest players in the space, including Uniswap, Sushiswap, Quickswap and more"
          />
          <meta property="og:url" content="https://apy.vision" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@APYVision" />
          <meta
            name="twitter:title"
            content="Growing the liquidity providing space with our partners - APY.vision"
          />
          <meta
            name="twitter:description"
            content="We are proud to have partnerships with some of the largest players in the space, including Uniswap, Sushiswap, Quickswap and more"
          />
          <meta name="twitter:creator" content="@APYVision" />
          <meta
            property="twitter:image"
            content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
          />
          <meta name="twitter:domain" content="apy.vision" />
        </Helmet>
        <div className="partners-header">
          <h1 as="h1" size="lg">
            Partnerships
          </h1>
          <p className="partners-container__text">
            We are proud to be working with our partners:
          </p>
        </div>
        <div className="partners-wrapper">
          {partnersData.map(({ name, link, logo, text }, index) => (
            <div key={index} className="partners-card">
              <div className="partners-card__info">
                <div className="partners-card__image">
                  <a
                    className="partners-card__link"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src={`/partners/${logo}`}
                      alt="logos"
                      style={
                        shouldManipulateImage(name)
                          ? {
                              width: "100%",
                              maxWidth: "250px",
                              marginBottom: "35px",
                              marginTop: "50px",
                              maxHeight: "150px",
                            }
                          : {}
                      }
                    />
                  </a>
                </div>

                <div className="partners-card__text">
                  {text}{" "}
                  <a
                    className="partners-card__link"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Visit {name} »
                  </a>
                </div>
              </div>
            </div>
          ))}
          <div className="partners-card partners-work">
            <h5 className="partners-card__heading">Let’s work together.</h5>
            <p className="partners-card__text">
              APY.vision is open to partnerships
            </p>

            <a href="mailto:aaron@apy.vision" target="_blank">
              <button className=" partners-card__contact">Get in touch</button>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
