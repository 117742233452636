import React, { useEffect, useState } from "react";
import Wallet from "../../assets/wallet.svg";
import axios from "axios";
import { BLOG_URL } from "../../constants";
import { ArrowRightCircle } from "react-feather";
import { IsMobileDevice } from "../../helpers/window";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useWindowSize } from "@react-hook/window-size";

export default () => {
  const [blogs, setBlogs] = useState(0);
  const isMobileDevice = IsMobileDevice();
  const [width, height] = useWindowSize();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 865, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    async function fetchBlogs() {
      const response = await axios.get(
        `${BLOG_URL}/wp-json/wp/v2/posts/?_embed&per_page=3&author=`
      );

      if (response) {
        setBlogs(response.data);
      }
    }
    fetchBlogs();
  }, [blogs]);

  return (
    <>
      <div className="blog-container">
        <div className="blog-container__headings">
          <h2 className="blog-container__headings-main">
            Invest confidently with our latest articles:
          </h2>
          <h4 className="blog-container__headings-guides">
            <a
              href="https://blog.apy.vision/"
              target="_blank"
              style={{ display: "flex", alignItems: "center" }}
            >
              View more guides &nbsp;
              <ArrowRightCircle size="22" stroke="#6b57b8" />
            </a>
          </h4>
        </div>

        <div className="blog">
          {isMobileDevice || width <= 865 ? (
            blogs ? (
              <Carousel
                containerClass="carousel-container"
                responsive={responsive}
                showDots={true}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
              >
                {blogs.map((blog, index) => (
                  <div key={index} className="blog-card">
                    <div className="blog-card__image">
                      <img src={blog._embedded["wp:featuredmedia"][0].link} />
                    </div>
                    <div className="blog-card__info">
                      <p className="blog-card__info-heading">
                        {blog.title.rendered}
                      </p>
                      <p className="blog-card__info-text">
                        {blog.excerpt.rendered
                          .replace("<p>", "")
                          .replace("</p>", "")
                          .substring(0, 200)}
                        ...
                      </p>
                      <a href={blog.guid.rendered} target="_blank">
                        <button className="blog-card__info-button">
                          Read more
                        </button>
                      </a>
                    </div>
                  </div>
                ))}
              </Carousel>
            ) : null
          ) : blogs ? (
            blogs.map((blog, index) => (
              <div key={index} className="blog-card">
                <div className="blog-card__image">
                  <img src={blog._embedded["wp:featuredmedia"][0].link} />
                </div>
                <div className="blog-card__info">
                  <p className="blog-card__info-heading">
                    {blog.title.rendered}
                  </p>
                  <p className="blog-card__info-text">
                    {blog.excerpt.rendered
                      .replace("<p>", "")
                      .replace("</p>", "")
                      .substring(0, 200)}
                    ...
                  </p>
                  <a href={blog.guid.rendered} target="_blank">
                    <button className="blog-card__info-button">
                      Read more
                    </button>
                  </a>
                </div>
              </div>
            ))
          ) : null}
        </div>
      </div>
    </>
  );
};
