import React, { useState } from "react";
import logo from "../../assets/logo.svg";
import Menu from "../Menu";
import { MenuToggleElem } from "../Menu/Menu.styles";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { MenuEnterpriseToggleElem } from "../MenuEnterprise/Menu.styles";
import MenuEnterprise from "../MenuEnterprise";

export default () => {
  const [isOpen, toggleMenuState] = useState(false);
  const location = useLocation();
  const toggleMenu = (event) => {
    event.preventDefault();
    toggleMenuState(!isOpen);
  };

  return (
    <div className="header-container">
      <div className="logo">
        <Link to="/">
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
      </div>
      {location.pathname === "/apis" ? (
        <>
          <MenuEnterpriseToggleElem
            href=""
            aria-label="Open main menu"
            onClick={toggleMenu}
          >
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 2V0H0V2H20ZM20 6V8H0V6H20ZM20 12V14H0V12H20Z"
                fill="#6B57B8"
              />
            </svg>
          </MenuEnterpriseToggleElem>
          <MenuEnterprise onClose={toggleMenu} isOpen={isOpen} />{" "}
        </>
      ) : (
        <>
          <MenuToggleElem
            href=""
            aria-label="Open main menu"
            onClick={toggleMenu}
          >
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 2V0H0V2H20ZM20 6V8H0V6H20ZM20 12V14H0V12H20Z"
                fill="#6B57B8"
              />
            </svg>
          </MenuToggleElem>

          <Menu onClose={toggleMenu} isOpen={isOpen} />
        </>
      )}
    </div>
  );
};
