import React, { useEffect } from "react";
import Feature from "../components/Feature/Feature";
import Info from "../components/Info";
import Hero from "../components/Hero";
import Plan from "../components/InfoPlan";
import Blog from "../components/Blog";
import PartnerInfo from "../components/PartnerInfo";
import { Helmet } from "react-helmet";
import { track } from "../components/Amplitude/Tracker";
import { MaybeInitCookie } from "../components/Amplitude/AmplitudeInitializer";

function Home() {
  MaybeInitCookie();
  useEffect(() => {
    track("viewed_marketing_site");
  }, []);
  return (
    <div className="App">
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="%PUBLIC_URL%/favicon.png" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Data platform for DeFi investors and protocols</title>
        <meta
          name="description"
          content="Find the most profitable liquidity pools, calculate liquidity pool performance, impermanent losses and track yield farming rewards in one place"
        />
        <meta property="image" content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png" />
        <meta property="og:title" content="Data platform for DeFi investors and protocols" />
        <meta
          property="og:description"
          content="Find the most profitable liquidity pools, calculate liquidity pool performance, impermanent losses and track yield farming rewards in one place"
        />
        <meta property="og:url" content="https://apy.vision" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="twitter:site" content="@APYVision" />
        <meta name="twitter:title" content="Data platform for DeFi investors and protocols" />
        <meta
          name="twitter:description"
          content="Find the most profitable liquidity pools, calculate liquidity pool performance, impermanent losses and track yield farming rewards in one place"
        />
        <meta name="twitter:creator" content="@APYVision" />
        <meta property="twitter:image" content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png" />
        <meta name="twitter:domain" content="apy.vision" />
      </Helmet>
      <Hero />
      <Info />
      <Feature />

      <Blog />
      <PartnerInfo />
    </div>
  );
}

export default Home;
