import { ExternalLink } from "react-feather";

export default () => {
  return (
    <>
      <div className="hero-container">
        <div className="hero">
          <h1 className="hero-heading">
            Data platform for <span className="hero-heading-color">DeFi</span>{" "}
            investors and protocols
          </h1>
          <p className="hero-text">
            Track your liquidity pool gains and impermanent loss and get the
            data you need to know when to enter and exit liquidity pools or
            yield farms to maximize your returns. Find the most profitable
            opportunities before others
          </p>
          <a
            className="button-link"
            href="https://app.apy.vision/"
            rel="noopener"
            target="_blank"
          >
            <button className="hero-button">
              <span className="button-text" id="enter-theapp-main">
                Enter the app
              </span>
              <ExternalLink color="white" size="16" strokeWidth="3px" />
            </button>
          </a>
        </div>
      </div>
      <div className="image-container"></div>
    </>
  );
};
