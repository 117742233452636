export const API_URL = "https://api.apy.vision";
export const BLOG_URL = "https://blog.apy.vision";
export const PARTNERS = {
  TheGraph: 'The Graph',
  "1inch": "1inch",
  Avalanche: "Avalanche",
  Aura: "Aura Finance",
  TraderJoe: "Trader Joe",
  SpiritSwap: "SpiritSwap",
  Nansen: "Nansen",
  Balancer: "Balancer",
  Uniswap: "Uniswap",
  Sushiswap: "Sushiswap",
  Quickswap: "Quickswap",
  Polygon: "Polygon",
  Index: "Index",
  FraxFinance: "Frax-Finance",
  FantomFoundation: "Fantom Foundation",
  VisorFinance: "Visor Finance",
  PickleFinance: "Pickle Finance",
  GnosisChain: "Gnosis Chain",
  Coingecko: "Coingecko",
  Aavegotchi: "Aavegotchi",
  Swapr: "Swapr",
  Warp: "Warp",
  Harvest: "Harvest",
  BenchmarkProtocol: "Benchmark Protocol",
  "88mph": "88mph",
  ValueDefi: "Value Defi",
  Pangolin: "Pangolin",
  YAMFinance: "YAM Finance",
};
