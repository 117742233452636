import React from "react";
import { Helmet } from "react-helmet";

function TermsCondition() {
  return (
    <div className="vision-container">
      <Helmet>
        <title>Terms and Conditions</title>
        <meta
          name="description"
          content="Our mission is to help all liquidity providers across the DeFi space make confident investment decisions and successfully maximise their returns."
        />
        <meta
          property="og:title"
          content="About the VISION token - APY.vision"
        />
        <meta
          property="og:description"
          content="Our mission is to help all liquidity providers across the DeFi space make confident investment decisions and successfully maximise their returns."
        />
        <meta property="og:url" content="https://apy.vision" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@APYVision" />
        <meta
          name="twitter:title"
          content="About the VISION token - APY.vision"
        />
        <meta
          name="twitter:description"
          content="Our mission is to help all liquidity providers across the DeFi space make confident investment decisions and successfully maximise their returns."
        />
        <meta name="twitter:creator" content="@APYVision" />
        <meta
          property="twitter:image"
          content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
        />
        <meta name="twitter:domain" content="apy.vision" />
      </Helmet>
      <div className="vision-header">
        <h1 className="vision-container__heading">Terms and Conditions</h1>

        <p className="vision-container__text">
          <div className="policy-text">Welcome to APY Vision!</div>

          <div className="policy-text">
            These terms and conditions outline the rules and regulations for the
            use of APY Vision's Website, located at&nbsp;
            <a href="https://apy.vision/" target="_blank">
              https://apy.vision/
            </a>
          </div>

          <div className="policy-text">
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use APY Vision if you do not agree to
            take all of the terms and conditions stated on this page.
          </div>

          <div className="policy-text">
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company’s terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves.
          </div>

          <div className="policy-text">
            All terms refer to the offer, acceptance, and consideration of
            payment necessary to undertake the process of our assistance to the
            Client in the most appropriate manner for the express purpose of
            meeting the Client’s needs in respect of provision of the Company’s
            stated services, in accordance with and subject to, prevailing law
            of Singapore. Any use of the above terminology or other words in the
            singular, plural, capitalization and/or he/she or they, are taken as
            interchangeable and therefore as referring to the same.
          </div>
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">Cookies</h3>
        <p className="distribution-info__content-text">
          We employ the use of analytics cookies. By accessing APY Vision, you
          agreed to use cookies in agreement with the APY Vision's Privacy
          Policy{" "}
          <a href="https://apy.vision/privacy-policy" target="_blank">
            (https://apy.vision/privacy-policy)
          </a>
          .
        </p>

        <p className="distribution-info__content-text">
          Most interactive websites use cookies to let us retrieve the user’s
          details for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website.
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">License</h3>

        <p className="distribution-info__content-text">
          Unless otherwise stated, APY Vision and/or its licensors own the
          intellectual property rights for all material on APY Vision. All
          intellectual property rights are reserved. You may access this from
          APY Vision for your own personal and enterprise use subject to
          restrictions set in these terms and conditions.
        </p>

        <p className="distribution-info__content-text">
          You must not, without consent:
        </p>

        <ul className="distribution-info_ul bottom">
          <li>Sell, rent or sub-license material from APY Vision</li>
          <li>Reproduce, duplicate or copy material from APY Vision</li>
          <li>Redistribute content from APY Vision</li>
        </ul>

        <p className="distribution-info__content-text">
          This Agreement shall begin on the date hereof.
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">Your Privacy</h3>

        <p className="distribution-info__content-text">
          Please read Privacy Policy{" "}
          <a href="https://apy.vision/privacy-policy" target="_blank">
            (https://apy.vision/privacy-policy)
          </a>
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">
          Third-party services
        </h3>

        <p
          className="distribution-info__content-text"
          style={{ fontWeight: "bold", fontSize: 18 }}
        >
          Transak
        </p>

        <p className="distribution-info__content-text">
          Transak{" "}
          <a href="https://transak.com" target="_blank">
            (https://transak.com/)
          </a>{" "}
          provides a developer integration to onboard global users from fiat to
          crypto and back using local payment methods.
        </p>

        <p className="distribution-info__content-text">
          Apy.vision uses Transek’s third-party payment services to provide fiat
          payment options on its paid plans.
        </p>

        <p className="distribution-info__content-text">
          Transak is a trading name of Light Technology Limited (a company
          registered in England & Wales, Company Number: 11539646). Transak is a
          registered cryptoasset firm with the UK Financial Conduct Authority
          (FRN: 928910) under the Money Laundering, Terrorist Financing and
          Transfer of Funds (Information on the Payer) Regulations 2017 (as
          amended) in respect of its activities in cryptoassets.
        </p>

        <p className="distribution-info__content-text">
          Before using this integration, please read more about Transak’s
          privacy policy and terms of services here.
        </p>
        <ul className="distribution-info_ul bottom">
          <li>
            Privacy policy{" "}
            <a href="https://transak.com/privacy-policy" target="_blank">
              (https://transak.com/privacy-policy)
            </a>{" "}
          </li>
          <li>
            Terms of Service&nbsp;
            <a href="https://transak.com/terms-of-service" target="_blank">
              (https://transak.com/terms-of-service)
            </a>{" "}
          </li>
        </ul>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">Disclaimer</h3>

        <p className="distribution-info__content-text">
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </p>
        <ul className="distribution-info_ul bottom">
          <li>
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation
          </li>
          <li>
            limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </li>
          <li>
            exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </li>
        </ul>

        <p className="distribution-info__content-text">
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </p>
      </div>

      <div className="distribution-info__content">
        <h3 className="distribution-info__content-heading">Contact Us</h3>

        <p className="distribution-info__content-text">
          For any questions or concerns regarding your privacy, you may contact
          us using the following details:
        </p>

        <p className="distribution-info__content-text">
          APY Vision
          <br />
          info@apy.vision
        </p>
      </div>
    </div>
  );
}

export default TermsCondition;
