import styled, { css } from "styled-components";

const FixedPosition = css`
  @supports (position: fixed) {
    position: fixed;
  }
`;

const MenuCloseElem = styled.a`
  position: absolute;
  right: 44px;
  top: 20px;
  z-index: ${(props) => (props.isOpen ? 1001 : "initial")};

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  @media (min-width: 876px) {
    display: none !important;
  }
`;

const MenuListElem = styled.ul`
  ${(props) =>
    props.isOpen &&
    css`
      position: relative;
      z-index: 1000;
    `};

  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #ffffff;
  align-self: flex-start;
  @media (max-width: 876px) {
    margin-top: 90px;
  }

  @media (min-width: 876px) {
    display: flex;
    padding: 0;
    box-shadow: none;
    height: auto;
    width: auto;
    background: none;
  }
`;

const MenuListItemElem = styled.li``;

const MenuElem = styled.nav`
  position: absolute;
  ${FixedPosition};
  display: none;
  left: 0;
  top: -100vh;
  height: 100%;
  transition: top 0.3s ease;
  z-index: 9;

  ${(props) =>
    props.isOpen &&
    css`
      display: flex;
      width: 100vw;
      height: 100vh;
      right: 0;
      top: 0;
      outline: none;
      background: #ffffff;
    `}

  &[aria-expanded="true"] ${MenuCloseElem} {
    z-index: 1001;
  }

  &[aria-expanded="true"] ${MenuListElem} {
    display: relative;
    z-index: 1000;
  }

  @media (min-width: 876px) {
    position: relative;
    left: auto;
    top: auto;
    display: flex;
    justify-content: space-between;
  }

  a {
    display: block;
  }
`;

const MenuToggleElem = styled.a`
  @media (min-width: 876px) {
    display: none !important;
  }
`;

export {
  MenuCloseElem,
  MenuElem,
  MenuListElem,
  MenuListItemElem,
  MenuToggleElem,
};
