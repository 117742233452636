import React from "react";
import { IsMobileDevice } from "../../helpers/window";
import "react-multi-carousel/lib/styles.css";
import { ArrowLeftCircle } from "react-feather";
import { ArrowRightCircle } from "react-feather";
import { useWindowSize } from "@react-hook/window-size";

const slideWidth = 30;

const _items = [
  {
    feature: {
      srcMobile: "/feature-1.png",
      srcDesktop: "/feature-1@2x.png",
      heading: "Track your pools across protocols and networks",
    },
  },
  {
    feature: {
      srcMobile: "/feature-2.png",
      srcDesktop: "/feature-2@2x.png",
      heading: "Monitor your positions for impermanent losses",
    },
  },
  {
    feature: {
      srcMobile: "/feature-3.png",
      srcDesktop: "/feature-3@2x.png",
      heading: "Track your farming rewards and vaults",
    },
  },
  {
    feature: {
      srcMobile: "/feature-4.png",
      srcDesktop: "/feature-4@2x.png",
      heading: "Analyse historic APYs and health metrics",
    },
  },
  {
    feature: {
      srcMobile: "/feature-5.png",
      srcDesktop: "/feature-5@2x.png",
      heading: "Find new pools to join",
    },
  },
];

const length = _items.length;
_items.push(..._items);

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position, idx) => {
  const item = {
    styles: {
      transform: `translateX(${position * slideWidth}rem)`,
    },
    feature: _items[idx].feature,
  };

  switch (position) {
    case length - 1:
    case length + 1:
      item.styles = {
        ...item.styles,
        filter: "grayscale(1)",
        display: "none",
      };
      break;
    case length:
      break;
    default:
      item.styles = { ...item.styles, opacity: 0, display: "none" };
      break;
  }

  return item;
};

const previousItem = (position, idx) => {
  const item = {
    styles: {
      transform: `translateX(${position}* 1.5 rem)`,
      position: "absolute",
    },
    feature: _items[idx].feature,
  };

  switch (position) {
    case length - 1:
    case length + 1:
      item.styles = {
        ...item.styles,
        filter: "grayscale(1)",
        display: "none",
      };
      break;
    case length:
      break;
    default:
      item.styles = { ...item.styles, opacity: 0, display: "none" };
      break;
  }

  return item;
};

const nextItem = (position, idx) => {
  const item = {
    styles: {
      transform: `translateX(${position} * 1.5 rem)`,
      position: "absolute",
    },
    feature: _items[idx].feature,
  };

  switch (position) {
    case length - 1:
    case length + 1:
      item.styles = {
        ...item.styles,
        filter: "grayscale(1)",
        display: "none",
      };
      break;
    case length:
      break;
    default:
      item.styles = { ...item.styles, opacity: 0, display: "none" };
      break;
  }

  return item;
};

const CarouselSlideItem = ({ pos, idx, activeIdx }) => {
  const item = createItem(pos, idx, activeIdx);
  const isMobileDevice = IsMobileDevice();
  const [width, height] = useWindowSize();

  return (
    <li className="carousel__slide-item" style={item.styles}>
      {/* <div className="previous-text"> {item.feature.previous}</div> */}
      <div className="carousel-slide-item__body">
        <h4>{item.feature.heading}</h4>
      </div>
      <div className="carousel__slide-item-img-link">
        {isMobileDevice || width <= 865 ? (
          <img src={item.feature.srcMobile} alt={item.feature.title} />
        ) : (
          <img src={item.feature.srcDesktop} alt={item.feature.title} />
        )}
      </div>
    </li>
  );
};

const CarouselPreviousItem = ({ pos, idx, activeIdx }) => {
  const item = previousItem(pos, idx, activeIdx);
  const isMobileDevice = IsMobileDevice();
  const [width, height] = useWindowSize();

  return (
    <span className="carousel-previous__item previous-item" style={item.styles}>
      <h4>{item.feature.heading}</h4>
    </span>
  );
};

const CarouselNextItem = ({ pos, idx, activeIdx }) => {
  const item = nextItem(pos, idx, activeIdx);
  const isMobileDevice = IsMobileDevice();
  const [width, height] = useWindowSize();

  return (
    <span className="carousel-next__item next-item" style={item.styles}>
      <h4>{item.feature.heading}</h4>
    </span>
  );
};

const keys = Array.from(Array(_items.length).keys());

export default () => {
  const [items, setItems] = React.useState(keys);
  const [previousItem, setPreviousItem] = React.useState(keys);

  const [isTicking, setIsTicking] = React.useState(false);
  const [activeIdx, setActiveIdx] = React.useState(0);
  const bigLength = items.length;

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i + jump) % bigLength]);
      });
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
      });
    }
  };

  React.useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  React.useEffect(() => {
    setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
  }, [items]);

  return (
    <div className="feature-section">
      <div className="feature-container" style={{ position: "relative" }}>
        <div>
          {items.map((pos, i) => (
            <>
              <div
                onClick={() => prevClick()}
                className="previous-item__button"
              >
                {activeIdx === 0 ? (
                  <CarouselPreviousItem
                    key={4}
                    idx={4}
                    pos={pos}
                    activeIdx={4}
                  />
                ) : (
                  <CarouselPreviousItem
                    key={i - 1}
                    idx={i}
                    pos={pos - 1}
                    activeIdx={activeIdx}
                  />
                )}
              </div>
              <div onClick={() => nextClick()} className="next-item__button">
                {activeIdx === 0 || activeIdx === -1 ? (
                  <CarouselNextItem key={1} idx={1} pos={pos} activeIdx={1} />
                ) : (
                  <CarouselNextItem
                    key={i + 1}
                    idx={i}
                    pos={pos + 1}
                    activeIdx={activeIdx + 1}
                  />
                )}
              </div>
            </>
          ))}
        </div>
        <h2 className="feature-container__heading">Our features</h2>
        <div className="carousel__wrap">
          <div className="carousel__inner">
            <button
              className="carousel__btn carousel__btn--prev"
              onClick={() => prevClick()}
            >
              <ArrowLeftCircle size="22" stroke="#6b57b8" />
            </button>

            <div className="carousel__container">
              <ul className="carousel__slide-list">
                {items.map((pos, i) => (
                  <>
                    <CarouselSlideItem
                      key={i}
                      idx={i}
                      pos={pos}
                      activeIdx={activeIdx}
                    />
                  </>
                ))}
              </ul>
            </div>

            <button
              className="carousel__btn carousel__btn--next"
              onClick={() => nextClick()}
            >
              <ArrowRightCircle size="22" stroke="#6b57b8" />
            </button>
          </div>
        </div>
        {/* <div>
          {items.map((pos, i) => (
            <>
              {activeIdx === 0 || activeIdx === -1 ? (
                <CarouselNextItem key={1} idx={1} pos={pos} activeIdx={1} />
              ) : (
                <CarouselNextItem
                  key={i + 1}
                  idx={i}
                  pos={pos + 1}
                  activeIdx={activeIdx + 1}
                />
              )}
            </>
          ))}
        </div> */}
      </div>
      <div className="feature-image-container"></div>
    </div>
  );
};
