import React, { useEffect, useRef, useState } from "react";
import EnterpriseLogo from "../assets/EnterpriseLogo";
import Rocket from "../assets/rocket_enterprice.svg";
import Light from "../assets/light.svg";
import Victory from "../assets/victory.svg";
import EnterpriseInformation from "../components/Enterprise/EnterpriseInformation";
import { useWindowSize } from "@react-hook/window-size";
import "react-multi-carousel/lib/styles.css";
import { Carousel } from "react-responsive-carousel";
import "dotenv";
import { Helmet } from "react-helmet";
import Insider from "../assets/apis/insider.svg";
import CoinGecko from "../assets/apis/coingecko.svg";
import CoinDesk from "../assets/apis/coindesk.svg";
import CoinTelegraph from "../assets/apis/coin-telegraph.svg";
import Yahoo from "../assets/apis/yahoo.svg";
import DefiPulse from "../assets/apis/defi-pulse.svg";
import DefiPrime from "../assets/apis/defiprime.svg";
import Investing from "../assets/apis/investing.svg";
import Nansen from "../assets/apis/nansen.svg";

const Apis = () => {
  const [width] = useWindowSize();

  const featured = [
    {
      icon: Insider,
      link: "https://www.businessinsider.com/top-passive-ways-earn-crypto-ceo-of-blockchain-investment-firm-2022-5",
    },
    {
      icon: CoinGecko,
      link: "https://assets.coingecko.com/reports/Research/Deep-Dive-into-Decentralized-Leveraged-ETFs-Part-2.pdf",
    },
    {
      icon: CoinDesk,
      link: "https://www.coindesk.com/markets/2021/01/05/ethereum-the-last-bastion-for-yield",
    },
    {
      icon: CoinTelegraph,
      link: "https://cointelegraph.com/press-releases/impermanent-loss-cryptos-silent-killer-threatens-the-core-tenets-of-defi-bancor",
    },
    {
      icon: Yahoo,
      link: "https://finance.yahoo.com/news/impermanent-loss-crypto-silent-killer-181500277.html",
    },
    { icon: DefiPulse, link: "https://www.defipulse.com/defi-list" },
    { icon: DefiPrime, link: "https://defiprime.com/defi-analytics" },
    {
      icon: Investing,
      link: "https://www.investing.com/news/cryptocurrency-news/quickswap--the-dex-curating-an-economical-and-scalable-way-to-swap-tokens-2507621",
    },
    {
      icon: Nansen,
      link: "https://www.nansen.ai/post/announcing-defi-paradise-the-defi-overview-dashboard",
    },
  ];

  const information = [
    {
      name: "Historical user performance",
      text: `Get the historical financial performance of any users, including data on rewards, fees, profit and loss, impermanent loss, market gains, APYs, and more.`,
      image: Victory,
    },
    {
      name: "Historical pool performance",
      text: `Get access to historical liquidity pool, farming and vaults data and calculate any metrics you need. With our APIs you can track: pool level APY, impermanent loss, pool reserves, volume, and more`,
      image: Light,
    },
    {
      name: "Expanding coverage",
      text: `We provide a growing list of APIs covering various protocols across multiple chains.`,
      image: Rocket,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Get access to industry-leading user financial data for web3
        </title>
        <meta
          name="description"
          content="APIs to unlock millions of data points for historical profit/loss tracking on liquidity providing, yield farming, and vault activities."
        />
        <meta
          property="og:title"
          content="Get access to industry-leading historical profit/loss tracking for DeFi"
        />
        <meta
          property="og:description"
          content="APIs to unlock millions of data points for historical profit/loss tracking on liquidity providing, yield farming, and vault activities."
        />
        <meta
          name="twitter:title"
          content="Get access to industry-leading historical profit/loss tracking for DeFi"
        />
        <meta
          name="twitter:description"
          content="APIs to unlock millions of data points for historical profit/loss tracking on liquidity providing, yield farming, and vault activities."
        />
      </Helmet>

      <div className={"Enterprise"}>
        {width <= 480 ? (
          <div className={"Enterprise_container"}>
            <EnterpriseLogo width={width} />
            <div className={"Enterprise_OverviewTitle"}>
              <h1 className={"Enterprise_Title"}>
                Get access to{" "}
                <span className={"Enterprise_Special"}>industry-leading</span>{" "}
                user financial data for web3
              </h1>
              <p className={"Enterprise_Paragraph"}>
                Utilize our feature-rich APIs to unlock millions of data points
                for historical profit/loss tracking on a wide range of DeFi
                activity such as liquidity providing, lending & borrowing,
                staking, yield farming, and vault investments.
              </p>
              <div
                style={
                  width <= 480
                    ? { display: "flex", flexDirection: "column" }
                    : {}
                }
              >
                <button
                  style={
                    width <= 480
                      ? { width: "100%", marginRight: 0, marginBottom: 8 }
                      : { marginRight: 8 }
                  }
                  className={"Enterprise_get_in_touch"}
                  data-tally-open="w2XA1A"
                  data-tally-layout="modal"
                  data-tally-width="600"
                  data-tally-emoji-animation="wave"
                  data-tally-auto-close="0"
                >
                  Get in touch
                </button>{" "}
                <button
                  style={
                    width <= 480
                      ? {
                          width: "100%",
                          background: "#E9EBEE",
                          color: "#6B57B8",
                        }
                      : { background: "#E9EBEE", color: "#6B57B8" }
                  }
                  className={"Enterprise_get_in_touch demo"}
                  onClick={() =>
                    window.open("https://docs.apy.vision/docs", "_blank")
                  }
                >
                  API Docs
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={"Enterprise_container"}>
            <div className={"Enterprise_OverviewTitle"}>
              <h1 className={"Enterprise_Title"}>
                Get access to{" "}
                <span className={"Enterprise_Special"}>industry-leading</span>{" "}
                user financial data for web3
              </h1>
              <p className={"Enterprise_Paragraph"}>
                Utilize our feature-rich APIs to unlock millions of data points
                for historical profit/loss tracking on a wide range of DeFi
                activity such as liquidity providing, lending & borrowing,
                staking, yield farming, and vault investments.
              </p>
              <div
                style={
                  width <= 480
                    ? { display: "flex", flexDirection: "column" }
                    : {}
                }
              >
                <button
                  style={
                    width <= 480
                      ? { width: "100%", marginRight: 0, marginBottom: 8 }
                      : { marginRight: 8 }
                  }
                  className={"Enterprise_get_in_touch"}
                  data-tally-open="w2XA1A"
                  data-tally-layout="modal"
                  data-tally-width="600"
                  data-tally-emoji-animation="wave"
                  data-tally-auto-close="0"
                >
                  Get in touch
                </button>{" "}
                <button
                  style={
                    width <= 480
                      ? {
                          width: "100%",
                          background: "#E9EBEE",
                          color: "#6B57B8",
                        }
                      : { background: "#E9EBEE", color: "#6B57B8" }
                  }
                  className={"Enterprise_get_in_touch demo"}
                  onClick={() =>
                    window.open("https://docs.apy.vision/docs", "_blank")
                  }
                >
                  API Docs
                </button>
              </div>
            </div>
            <EnterpriseLogo width={width} />
          </div>
        )}

        <div className={"Enterprise_Information_Overview"}>
          <div className={"Enterprise_Information"}>
            {information.map(({ name, image, text }) => (
              <EnterpriseInformation src={image} title={name} value={text} />
            ))}
          </div>
        </div>
        <div className="featured-on enterprise">
          <div className="featured-on__heading">
            <h3>Featured on</h3>

            <div className={"featured-on__container"}>
              {width > 1030 ? (
                <>
                  <div className={"featured-on__container-flex"}>
                    {featured.slice(0, 4).map((feature, index) => (
                      <a
                        href={feature.link}
                        target={"_blank"}
                        className={"children"}
                        key={index}
                      >
                        <img src={feature.icon} />
                      </a>
                    ))}
                  </div>

                  <div className={"featured-on__container-flex"}>
                    {featured.slice(4).map((feature, index) => (
                      <a
                        href={feature.link}
                        target={"_blank"}
                        className={"children"}
                        key={index}
                      >
                        <img src={feature.icon} />
                      </a>
                    ))}
                  </div>
                </>
              ) : (
                <div className={"featured-on__container-flex"}>
                  {featured.map((feature, index) => (
                    <a
                      href={feature.link}
                      target={"_blank"}
                      className={"children"}
                      key={index}
                    >
                      <img src={feature.icon} />
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Apis;
