import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { ArrowRightCircle } from "react-feather";
import { IsMobileDevice } from "../../helpers/window";
import { useLocation } from "react-router-dom";

const SimpleForm = ({ status, message, className, onSubmitted }) => {
  const isMobileDevice = IsMobileDevice();
  const location = useLocation();

  let input, formId;
  const submit = () =>
    input &&
    formId &&
    input.value.indexOf("@") > -1 &&
    onSubmitted({
      EMAIL: input.value,
      MERGE1: formId.value,
    });

  return (
    <div className={className} style={{ position: "relative" }}>
      {status === "error" && (
        <div
          style={{
            fontFamily: " Nunito Sans",
            fontStyle: "normal",
            fontWeight: " normal",
            fontSize: "14px",
            lineHeight: " 20px",
            color: "#ef4d4d",
            position: " absolute",
            top: "80px",
            zIndex: "30",
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {status === "success" && (
        <div
          style={{
            fontFamily: " Nunito Sans",
            fontStyle: "normal",
            fontWeight: " normal",
            fontSize: "14px",
            lineHeight: " 20px",
            color: "#00ff00",
            position: " absolute",
            top: "80px",
            zIndex: "30",
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {isMobileDevice && location.pathname === "/enterprise" ? (
        <div style={{ height: "40px" }} />
      ) : (
        <>
          <label htmlFor="email">Subscribe to our newsletter</label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              ref={(node) => (input = node)}
              type="email"
              id="email"
              v-model="email"
              className={status === "error" ? "error" : "normal"}
              placeholder="Enter your email"
              autoCapitalize="off"
              autoCorrect="off"
            />
            <input
              ref={(node) => (formId = node)}
              type="hidden"
              name="MERGE1"
              className=""
              id="mce-FORMID"
            />

            <ArrowRightCircle
              size="36"
              fill="#6B57B8"
              className=""
              stroke="#ffffff"
              strokeWidth="1"
              onClick={submit}
              type="submit"
              id="submit"
              cursor="pointer"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default (props) => {
  const url = `https://vision.us2.list-manage.com/subscribe/post?u=b7d7cb3e06091a85af38302a0&id=d3fc578282`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <SimpleForm
            status={status}
            message={message}
            onSubmitted={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};
