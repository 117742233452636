import { getCookie, setCookie } from "react-use-cookie";
import amplitude from "amplitude-js";
import { useRouter } from "../../helpers/useRouter";

const possibleParamsArray = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];
export const MaybeInitCookie = () => {
  const cookie = getCookie("FIRST-TIME-USER");
  const router = useRouter();
  const props = possibleParamsArray.filter((q) =>
    Object.keys(router.query).includes(q)
  );
  if (!cookie) {
    if (props.length > 0) {
      var userProperties = {
        referral_source: router.query.utm_source,
        referral_medium: router.query.utm_medium,
        refferal_campaign: router.query.utm_campaign,
        refferal_term: router.query.utm_term,
        refferal_content: router.query.utm_content,
      };
      amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY);
      amplitude.getInstance().setUserProperties(userProperties);
    }
    setCookie("FIRST-TIME-USER", true);
  }
};
