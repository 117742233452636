import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ExternalLink } from "react-feather";
import { ArrowRight } from "react-feather";
import { MenuElem, MenuListElem, MenuCloseElem } from "./Menu.styles";
import logo from "../../assets/logo.svg";
import { IsMobileDevice } from "../../helpers/window";
import { useWindowSize } from "@react-hook/window-size";
export default ({ isOpen, onClose }) => {
  const isMobileDevice = IsMobileDevice();
  const [width, height] = useWindowSize();
  const location = useLocation();
  return (
    <MenuElem className="main-menu" aria-label="Main menu" isOpen={isOpen}>
      <div isOpen={isOpen} className="mobile-logo">
        <img src={logo} alt="logo" />
      </div>
      <MenuCloseElem href="" className="menu-close" aria-label="Close main menu" onClick={onClose} isOpen={isOpen}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99992 6.58579L14.3639 0.221826L15.7781 1.63604L9.41414 8L15.7781 14.364L14.3639 15.7782L7.99992 9.41421L1.63596 15.7782L0.221749 14.364L6.58571 8L0.221749 1.63604L1.63596 0.221826L7.99992 6.58579Z"
            fill="#6B57B8"
          />
        </svg>
      </MenuCloseElem>
      {isMobileDevice || width <= 865 ? (
        <MenuListElem isOpen={isOpen}>
          <ul className="header-container__nav">
            <li className="header-container__nav-link" onClick={onClose}>
              <Link to="/">
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                Home
              </Link>
            </li>
            <li
              className="header-container__nav-link"
              onClick={() => {
                window.open("/apis", "_blank");
                onClose();
              }}
            >
              <Link>
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                APIs
              </Link>
            </li>

            <li className="header-container__nav-link" onClick={onClose}>
              <Link to="/partners">
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                Partners
              </Link>
            </li>
            <li
              className="header-container__nav-link"
              onClick={() => {
                window.open(" https://blog.apy.vision/", "_blank");
                onClose();
              }}
            >
              <Link>
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                Blog
              </Link>
            </li>
            <li className="header-container__nav-link">
              <a href="mailto:info@apy.vision" target="_blank">
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                Contact
              </a>
            </li>
            <a
              className="button-link"
              onClick={() => {
                window.open(" https://app.apy.vision/", "_blank");
                onClose();
              }}
              target="_blank"
              rel="noopener"
            >
              <button className="header-container__button">
                <span className="button-text" id="enter-theapp-header">
                  {" "}
                  Enter app
                </span>
                <ExternalLink color="white" size="16" strokeWidth="3px" />
              </button>
            </a>
          </ul>
        </MenuListElem>
      ) : (
        <MenuListElem isOpen={isOpen}>
          <ul className="header-container__nav">
            <li className="header-container__nav-link">
              <Link to="/">
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                Home
              </Link>
            </li>
            <li
              className="header-container__nav-link"
              onClick={() => {
                window.open("/apis", "_blank");
                onClose();
              }}
            >
              <Link>
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                APIs
              </Link>
            </li>

            <li className="header-container__nav-link">
              <Link to="/partners">
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                Partners
              </Link>
            </li>
            <li
              className="header-container__nav-link"
              onClick={() => {
                // track("clicked_twitter");
                window.open(" https://blog.apy.vision/", "_blank");
              }}
            >
              <Link>
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                Blog
              </Link>
            </li>
            <li className="header-container__nav-link">
              <a href="mailto:info@apy.vision" target="_blank">
                <ArrowRight color="red" size="14" className="header-container__nav-link-icon" />
                Contact
              </a>
            </li>
            <a className="button-link" href="https://app.apy.vision/" target="_blank" rel="noopener">
              <button className="header-container__button">
                <span className="button-text" id="enter-theapp-header">
                  {" "}
                  Enter app
                </span>
                <ExternalLink color="white" size="16" strokeWidth="3px" />
              </button>
            </a>
          </ul>
        </MenuListElem>
      )}
    </MenuElem>
  );
};
