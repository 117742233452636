import React from "react";

const EnterpriseInformation = ({ src, title, value }) => {
  return (
    <div className={"Enterprise_info_responsive"}>
      <div className={"Enterprise_information_children"}>
        <div className={"img"}>
          <img src={src} alt={title} />
        </div>
        <p className={"title"}>{title}</p>

        <p className={"paragraph"}>{value}</p>
      </div>
    </div>
  );
};

export default EnterpriseInformation;
