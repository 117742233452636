import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../constants";
import { Helmet } from "react-helmet";

import RightArrow from "../assets/rightarrow.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Subscription from "./Subscription";
import NFT from "./NFT";
import {
  Analytics,
  Portfolio,
  Research,
} from "../components/Subscriptions/SubscriptionBox";
import { IsMobileDeviceMedium } from "../helpers/window";
import { Link, useLocation } from "react-router-dom";
import { track } from "../components/Amplitude/Tracker";

function ProPlans() {
  const [price, setPrice] = useState(0);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isMobileMedium = IsMobileDeviceMedium();

  const queryParameter = query.get("type")
    ? query.get("type")
    : "subscriptions";

  useEffect(() => {
    track("viewed_pro_plans");
  }, []);

  useEffect(() => {
    async function fetchPrice() {
      const response = await axios.get(`${API_URL}/pricing`);
      setPrice(response.data.visionPrice.toFixed(2));
    }

    fetchPrice();
  }, [price]);

  return (
    <div className="membership-container">
      <Helmet>
        <title>
          Unlock professional LP analytics features with our pro plans -
          APY.vision
        </title>
        <meta
          name="description"
          content="Get access to extended analytics, realtime market prices, and more features with our token-based membership or NFTs"
        />
        <meta
          property="og:title"
          content="Unlock professional LP analytics features with our pro plans -
            APY.vision"
        />
        <meta
          property="og:description"
          content="Get access to extended analytics, realtime market prices, and more features with our token-based membership or NFTs"
        />
        <meta property="og:url" content="https://apy.vision" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@APYVision" />
        <meta
          name="twitter:title"
          content="Unlock professional LP analytics features with our pro plans -
            APY.vision"
        />
        <meta
          name="twitter:description"
          content="Get access to extended analytics, realtime market prices, and more features with our token-based membership or NFTs"
        />
        <meta name="twitter:creator" content="@APYVision" />
        <meta
          property="twitter:image"
          content="https://s3.amazonaws.com/apyvision-public/Image_oOqePhD99X.png"
        />
        <meta name="twitter:domain" content="apy.vision" />
      </Helmet>
      <div className="membership-header">
        <h1 className="membership-container__heading">
          Find a plan that’s right for you
        </h1>
        <p className="membership-container__text-dark">
          Manage your liquidity pool positions, analyse pool performances and
          find the best APYs across chains
        </p>
        <div className="membership-container-subscriptions">
          <Link
            onClick={() => track("viewed_subscription_plans")}
            to="/pro-plans?type=subscriptions"
            className={`membership-child ${
              queryParameter === "subscriptions" && "active"
            }`}
            style={{ textDecoration: "none" }}
          >
            Subscriptions
          </Link>
          <Link
            onClick={() => track("viewed_lifetime_plans")}
            to="/pro-plans?type=lifetime-access"
            className={`membership-child ${
              queryParameter === "lifetime-access" && "active"
            }`}
            style={{ textDecoration: "none" }}
          >
            Lifetime access
          </Link>
        </div>
      </div>

      {queryParameter === "lifetime-access" ? (
        <p className="membership-container-sufficient">
          <p className="title">
            Hold sufficient membership tokens in your wallet
          </p>
        </p>
      ) : (
        <p className="membership-container-sufficient nft">
          <p className="title">
            Mint an NFT subscription and unlock all pro features
          </p>
          {!isMobileMedium ? (
            <p className="paragraph">
              or try for 30 days with our risk-free trial
            </p>
          ) : null}
        </p>
      )}

      {queryParameter === "lifetime-access" ? <Subscription /> : <NFT />}
      {queryParameter === "lifetime-access" ? (
        <div className="membership-container__disclaimer">
          <p className="">
            Please note that the VISION membership tokens are inherently
            valueless and should not be used for speculative/investment
            purposes.
          </p>
        </div>
      ) : (
        <div className="membership-container__disclaimernft">
          <b>
            *30 days trials cannot be renewed as monthly subscriptions and are
            meant for trial purposes only.
          </b>{" "}
          Full refund available in the first 3 days of risk-free trial.
        </div>
      )}

      {queryParameter === "lifetime-access" ? (
        <div className="vision">
          <p className="title" style={{ marginBottom: "16px" }}>
            Get Lifetime Membership tokens:
          </p>

          <div className="vision-btns">
            <a
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                fontWeight: "700",
                fontsize: "16px",
                lineHeight: "24px",
                margin: "0px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              href="https://v2.info.uniswap.org/pair/0xa323fc62c71b210e54171887445d7fca569d8430"
            >
              <button className="button button-width">On Ethereum</button>
            </a>
            <a
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                fontWeight: "700",
                fontsize: "16px",
                lineHeight: "24px",
                margin: "0px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              href="https://polygon.balancer.fi/#/trade/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x034b2090b579228482520c589dbD397c53Fc51cC"
            >
              <button className="button button-width">On Polygon</button>
            </a>
          </div>
        </div>
      ) : (
        <>
          <div className="vision">
            <p className="titleTwo">All subscription plans include:</p>
          </div>
          <div className="membership-container-subscriptions-boxes">
            <Portfolio />
            <Analytics />
            <Research />
          </div>
        </>
      )}

      <div className="subscription">
        <p className="title">
          {queryParameter === "subscriptions"
            ? "Looking for a free plan?"
            : "Looking for a subscription?"}
        </p>
        <a
          target="_blank"
          href={`${
            queryParameter === "subscriptions"
              ? "https://apy.vision/pro-plans?type=lifetime-access"
              : "https://apy.vision/pro-plans?type=subscriptions"
          }`}
          className="subscription-check"
          style={{ textDecoration: "none" }}
          onClick={() =>
            track(
              queryParameter === "subscriptions"
                ? "viewed_lifetime_plans"
                : "viewed_subscription_plans"
            )
          }
        >
          {queryParameter === "subscriptions"
            ? "Checkout our Lifetime access options"
            : "Checkout our subscription plans"}
          <img src={RightArrow} alt="rightarrow" />{" "}
        </a>
      </div>

      <div className="subscription-help">
        <p className="title">Need help deciding on a plan?</p>

        <a
          target="_blank"
          style={{
            color: "white",
            textDecoration: "none",
            fontWeight: "700",
            fontsize: "16px",
            lineHeight: "24px",
            margin: "0px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
          }}
          href="https://discord.com/invite/ePfGAYbqUq"
        >
          <button className="button button-width">Talk to us on Discord</button>
        </a>
      </div>
    </div>
  );
}

export default ProPlans;
